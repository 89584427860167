<template>
    <div class="app-container">
        <Box :type="'warning'" :active="true" style="margin-top:0" v-if="tax.useautoshipping === 0">
            <div slot="box-body">
                <ul class="rawUl">
                    <li v-html="$t('account.taxes.box1')"></li>
                </ul>
            </div>
        </Box>


        <el-card class="box-card">
            <div slot="header" class="clearfix">
                {{ $t('account.taxes.header-1') }}
                <div style="float:right;" v-if="tax.useautoshipping === 0">
                    <el-button
                        class="b-c-sgt"
                        type="primary"
                        size="mini"
                        icon="el-icon-check"
                        plain
                        @click.native="saveGeneralTax"
                    >
                        {{ $t('general.save') }}</el-button
                    >
                </div>
            </div>

            <div v-if="tax.useautoshipping === 0">
              <div>
                  <el-radio v-model="deliveryTax.type" :label="1" border>{{ $t('account.taxes.radio-1') }}</el-radio>
                  <el-radio v-model="deliveryTax.type" :label="2" border>{{ $t('account.taxes.radio-2') }}</el-radio>
                  <el-radio v-model="deliveryTax.type" :label="3" border>{{ $t('account.taxes.radio-3') }}</el-radio>
              </div>
              <div v-if="parseInt(deliveryTax.type) === 3">
                  <div class="separator"></div>
                  <el-tooltip
                      class="item"
                      effect="dark"
                      :content="$t('account.taxes.max-open-tax-explained')"
                      placement="top"
                  >
                      <span class="cel-color deschidere-taxa"
                          >{{ $t('account.taxes.max-tax') }}: {{ maxOpenTax }} Lei</span
                      >
                  </el-tooltip>
                  <el-input v-model="deliveryTax.value" :placeholder="$t('account.taxes.value-1')"></el-input>
              </div>
              <div class="separator"></div>

              <div>
                  <div class="sub-title">{{ $t('account.taxes.min-order') }}</div>
                  <el-input v-model="tax.minim"></el-input>
                  <div class="separator"></div>
                  <el-checkbox
                      v-model="tax.forBucharest"
                      @change="handleBucharestChange"
                      :label="$t('account.taxes.label-1')"
                      border
                  ></el-checkbox>
                  <div v-if="tax.forBucharest">
                      <div class="separator"></div>
                      <el-input v-model="tax.bucharestPrice" :placeholder="$t('account.taxes.ph-1')"></el-input>
                  </div>
              </div>
              <div class="separator"></div>
              <div class="sub-title">
                  {{ $t('account.taxes.title-1') }} (<el-tooltip
                      class="item"
                      effect="dark"
                      :content="$t('account.taxes.max-tax-explained')"
                      placement="top"
                  >
                      <span class="cel-color">{{ $t('account.taxes.max-tax') }}: {{ maxTax }} Lei</span> </el-tooltip
                  >)
              </div>
              <el-input v-model="tax.baza"></el-input>
              <div class="separator"></div>
              <div>
                  <div class="sub-title">{{ $t('account.taxes.title-2') }}</div>
                  <el-input v-model="tax.kg"></el-input>
              </div>
              <div class="separator"></div>
              <div>
                  <div class="sub-title">{{ $t('account.taxes.title-3') }}</div>
                  <el-input v-model="tax.kgPrice"></el-input>
              </div>
            </div>
            <div v-else>

              <router-link to="/cont/curieri">
                {{ $t('account.taxes.settings-moved') }}
              </router-link>
            </div>


        </el-card>
        <div class="separator"></div>

        <el-card class="box-card">
            <div slot="header" class="clearfix">
                {{ $t('account.taxes.header-2')
                }}<span style="font-weight: bold; text-decoration: underline;">{{
                    $t('account.taxes.header-2-1')
                }}</span
                >{{ $t('account.taxes.header-2-2') }}
            </div>
            <el-row :gutter="12">
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <div class="sub-title">{{ $t('account.taxes.title-4') }}</div>
                    <el-input
                        v-model="categoryFilter.keyword"
                        class="input-with-select"
                        :placeholder="$t('account.taxes.ph-2')"
                        @keyup.enter.native="applyCategoryFilter()"
                        size="small"
                    >
                        <el-button
                            class="b-c-acf"
                            slot="append"
                            icon="el-icon-search"
                            @click.native="applyCategoryFilter()"
                        >
                        </el-button>
                    </el-input>
                </el-col>
            </el-row>
            <div class="mini-separator"></div>
            <el-table :data="categorii" border style="width: 100%" ref="taxeTransportTable" size="mini">
                <el-table-column :label="$t('account.taxes.label-2')">
                    <template slot-scope="scope">
                        <span> {{ categorii[scope.$index].categories_name }} </span>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('account.taxes.label-3')">
                    <template slot-scope="scope">
                        <el-input size="small" v-model="categorii[scope.$index].valoare"></el-input>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('account.taxes.label-4')" width="70">
                    <template slot-scope="scope">
                        <el-switch v-model="categorii[scope.$index].impusa"></el-switch>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.actions')" width="230">
                    <template slot-scope="scope">
                        <el-button
                            class="b-c-mtt"
                            type="success"
                            size="mini"
                            icon="el-icon-check"
                            plain
                            @click.native="modificaTaxaTransport(scope.$index)"
                            >{{ $t('general.save') }}</el-button
                        >
                        <el-button
                            class="b-c-stt"
                            type="danger"
                            size="mini"
                            icon="el-icon-check"
                            plain
                            @click.native="stergeTaxaTransport(scope.$index)"
                            >{{ $t('general.delete') }}</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <div class="separator"></div>

        <el-card class="box-card">
            <div slot="header" class="clearfix">
                {{ $t('account.taxes.header-3')
                }}<span style="font-weight: bold;text-decoration: underline;">{{ $t('account.taxes.header-3-1') }}</span
                >{{ $t('account.taxes.header-3-2') }}
                <div style="float:right;">
                    <!-- <el-button type="info" size="mini" icon="el-icon-tickets" plain @click.native="saveForAll">Salveaza pentru toate categoriile</el-button> -->
                    <el-button
                        class="b-c-st"
                        type="primary"
                        size="mini"
                        icon="el-icon-check"
                        plain
                        @click.native="saveTax"
                    >
                        {{ $t('general.save') }}</el-button
                    >
                </div>
            </div>
            <div>
                <div class="sub-title">{{ $t('account.taxes.label-2') }}</div>
                <c-select @selected="categorySelected" :categ="selectedCateg"></c-select>
            </div>
            <div class="separator"></div>
            <div v-if="selectedCateg != null && selectedCateg != 0">
                <div>
                    <!-- <div class="sub-title">Impusa</div> -->
                    <el-checkbox v-model="values.impusa" :label="$t('account.taxes.label-4')"></el-checkbox>
                </div>
                <div class="separator"></div>
                <div>
                    <div class="sub-title">{{ $t('account.taxes.label-3') }}</div>
                    <el-input v-model="values.tax"></el-input>
                </div>
            </div>
        </el-card>
    </div>
</template>

<script>
import { getTransportTax, updateTransportTax, deleteTransportTax, updateDeliveryInformation } from '@/api/cont';

import { debugPromise, parseCategories } from '@/utils/index';
import has from 'lodash.has';
const CategorySelect = () => import('@/components/CategoriesSelect');
const Box = () => import('vue-info-box-element');
export default {
    components: {
        Box,
        'c-select': CategorySelect
    },
    data() {
        return {
            categorii: [],
            selectedCateg: 0,
            values: {
                tax: '',
                impusa: ''
            },
            tax: {
                minim: '',
                forBucharest: false,
                bucharestPrice: '',
                kg: '',
                kgPrice: '',
                baza: '',
                useautoshipping: 0
            },
            deliveryTax: {
                type: '',
                value: ''
            },
            categoryFilter: {
                keyword: ''
            },
            maxTax: 0,
            maxOpenTax: 0
        };
    },
    methods: {
        parseCategories(categories) {
            const parsedCategories = [];
            for (const i in categories) {
                const tempCat = {};
                tempCat.label = i;
                tempCat.value = i;
                tempCat.children = [];
                for (const j in categories[i]) {
                    tempCat.children.push({
                        label: categories[i][j],
                        value: j
                    });
                }
                parsedCategories.push(tempCat);
            }
            this.categories = parsedCategories;
        },
        saveTax() {
            const selCateg = this.selectedCateg;
            updateTransportTax({
                id: selCateg,
                impusa: this.values.impusa == true ? 1 : 0,
                newValue: this.values.tax
            })
                .then((res) => {
                    if (has(res, 'message') && has(res.message, 'success')) this.resetFields();

                    this.refreshListing();
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'updateTransportTax',
                            params: {
                                id: selCateg,
                                impusa: this.values.impusa == true ? 1 : 0,
                                newValue: this.values.tax
                            }
                        }
                    });
                    debugPromise('saveTax', e);
                });
        },
        resetFields() {
            this.values = {
                tax: '',
                impusa: ''
            };
            this.selectedCateg = 0;
        },
        handleBucharestChange(val) {
            if (val === false) this.tax.bucharestPrice = this.tax.minim;
        },
        handleCategoryChange(val) {
            if (val.length < 2) return false;
            getTransportTax({
                categ_id: val.slice(-1)[0]
            })
                .then((res) => {
                    if (!has(res, 'message')) return false;
                    if (res.message.length < 1) return false;
                    this.values.tax = 'undefined' != typeof res.message.categ[0] ? res.message.categ[0].valoare : '';
                    this.values.impusa =
                        'undefined' != typeof res.message.categ[0] ? res.message.categ[0].impusa == 1 : false;
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'getTransportTax',
                            params: {
                                categ_id: val.slice(-1)[0]
                            }
                        }
                    });
                    debugPromise('getTransportTax', e);
                });
        },
        saveGeneralTax() {
            if (
                parseFloat(this.maxTax) < parseFloat(this.tax.baza) &&
                Math.abs(parseFloat(this.tax.baza) - parseFloat(this.maxTax)) > 0.001
            ) {
                this.sbMsg({
                    type: 'error',
                    message: this.$t('account.taxes.max-tax-error')
                });
                return false;
            }

            if (
                this.deliveryTax.type == 3 &&
                parseFloat(this.maxOpenTax) < parseFloat(this.deliveryTax.value) &&
                Math.abs(parseFloat(this.deliveryTax.value) - parseFloat(this.maxOpenTax)) > 0.001
            ) {
                this.sbMsg({
                    type: 'error',
                    message: this.$t('account.taxes.max-open-tax-error')
                });
                return false;
            }

            this.tax.bucharestPrice = this.tax.forBucharest ? this.tax.bucharestPrice : this.tax.minim;
            const params = {
                minim_tara: this.tax.minim,
                minim_bucuresti: this.tax.bucharestPrice,
                baza: this.tax.baza,
                kgincluse: this.tax.kg,
                pretkginplus: this.tax.kgPrice,
                deschiderecolet:
                    this.deliveryTax.type == 1 ? -1 : this.deliveryTax.type == 2 ? 0 : this.deliveryTax.value
            };
            updateDeliveryInformation(params)
                .then((res) => {
                    if (!has(res, 'message')) return false;
                    if (res.message.length < 1) return false;
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'updateDeliveryInformation',
                            params
                        }
                    });
                    debugPromise('updateDeliveryInformation', e);
                });
        },
        saveDeliveryTax() {},
        modificaTaxaTransport(i) {
            updateTransportTax({
                id: this.categorii[i].id_categorie,
                impusa: this.categorii[i].impusa == true ? 1 : 0,
                newValue: this.categorii[i].valoare
            })
                .then((res) => {
                    if (has(res, 'message') && has(res.message, 'success')) this.resetFields();
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'updateTransportTax',
                            params: {
                                id: this.categorii[i].id_categorie,
                                impusa: this.categorii[i].impusa == true ? 1 : 0,
                                newValue: this.categorii[i].valoare
                            }
                        }
                    });
                    debugPromise('updateTransportTax', e);
                });
        },
        stergeTaxaTransport(i) {
            deleteTransportTax({
                id: this.categorii[i].id_categorie
            })
                .then((res) => {
                    if (has(res, 'message') && has(res.message, 'success')) this.resetFields();

                    this.categorii.splice(i, 1);
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'deleteTransportTax',
                            params: {
                                id: this.categorii[i].id_categorie
                            }
                        }
                    });
                    debugPromise('deleteTransportTax', e);
                });
        },
        refreshListing(name) {
            this.selectedCateg = 0;
            let data = {};
            if ('undefined' != typeof name) {
                data = {
                    name
                };
            }

            getTransportTax(data)
                .then((res) => {
                    // console.log(res);
                    if (!has(res, 'message')) return false;
                    if (res.message.length < 1) return false;
                    this.tax.kg = res.message.default.kgincluse;
                    this.tax.minim = res.message.default.minim_tara;
                    this.tax.bucharestPrice = res.message.default.minim_bucuresti;
                    this.tax.kgPrice = res.message.default.pretkginplus;
                    this.tax.useautoshipping = res.message.default.useautoshipping;
                    this.tax.baza = res.message.default.baza;
                    this.maxTax = res.message.maxTax;
                    this.maxOpenTax = res.message.maxPackageOpenTax;
                    if (parseInt(this.tax.bucharestPrice) !== parseInt(this.tax.minim)) {
                        this.tax.forBucharest = true;
                    }

                    if (res.message.default.deschiderecolet == -1) {
                        this.deliveryTax.type = 1;
                    } else if (res.message.default.deschiderecolet == 0) {
                        this.deliveryTax.type = 2;
                    } else {
                        this.deliveryTax.type = 3;
                        this.deliveryTax.value = res.message.default.deschiderecolet;
                    }

                    this.categorii = [];
                    for (const e in res.message.categ) {
                        res.message.categ[e].impusa = res.message.categ[e].impusa == 1;
                        this.categorii.push(res.message.categ[e]);
                    }
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'getTransportTax',
                            params: data
                        }
                    });
                    debugPromise('getTransportTax', e);
                });
        },
        applyCategoryFilter() {
            // add here check if needs reset
            // if(this.categoryFilter.keyword === '' || this.categoryFilter.length < 1) return false
            this.refreshListing(this.categoryFilter.keyword);
        },
        categorySelected(categ) {
            if (typeof categ.categories_id !== 'undefined' && parseInt(categ.categories_id) !== 0) {
                this.selectedCateg = categ.categories_id;
                getTransportTax({
                    categ_id: categ.categories_id
                })
                    .then((res) => {
                        if (!has(res, 'message')) return false;
                        if (res.message.length < 1) return false;
                        this.values.tax =
                            'undefined' != typeof res.message.categ[0] ? res.message.categ[0].valoare : '';
                        this.values.impusa =
                            'undefined' != typeof res.message.categ[0] ? res.message.categ[0].impusa == 1 : false;
                    })
                    .catch((e) => {
                        this.$reportToSentry(e, {
                            extra: {
                                fn: 'getTransportTax',
                                params: {
                                    categ_id: categ.categories_id
                                }
                            }
                        });
                        debugPromise('getTransportTax', e);
                    });
            }
        }
    },
    computed: {},
    watch: {
        'deliveryTax.type'() {
            // console.log(this.deliveryTax.type);
        }
    },
    created() {
        this.refreshListing();
    }
};
</script>

<style lang="scss">
.deschidere-taxa {
    font-size: 14px;
    margin-bottom: 10px;
}
</style>
